@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #484848;
}
.myContainer {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 5%;
}
.my_bg_yellow {
  background: linear-gradient(
    72deg,
    #f8ea69 calc(60% - 1rem),
    #f5e05d calc(60% - 1rem)
  );
}
.my_bg_green {
  background: linear-gradient(
    72deg,
    #51d98f calc(60% - 1rem),
    #43d48b calc(60% - 1rem)
  );
}
.my_bg_banner {
  background: linear-gradient(
    72deg,
    #d9f8dfbf calc(60% - 1rem),
    #43d48b calc(60% - 1rem)
  );
}
.my_bg_black {
  background: linear-gradient(
    72deg,
    #1c1c1c calc(60% - 1rem),
    #000 calc(60% - 1rem)
  );
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.my_font_custom {
  font-family: 'DM Serif Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header__pointer_form {
  content: "";
  position: absolute;
  bottom: 0.125rem;
  left: 0.125rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0 0 0.125rem 0;
  background: inherit;
  -webkit-transform: rotate(90deg) skew(16deg, 16deg);
  transform: rotate(90deg) skew(16deg, 16deg);
}
input,
input:focus {
  outline: none;
}
.header__pointer_form {
  content: '';
  position: absolute;
  bottom: -.4rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0 0 .125rem 0;
  background: inherit;
  -webkit-transform: rotate(90deg) skew(16deg,16deg);
  transform: rotate(90deg) skew(16deg,16deg);
  background-color: #f86e53;
}
